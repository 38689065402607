





import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import StoreClosed from "@/components/StoreClosed.vue";

@Component({
    components: { StoreClosed },
})
export default class StoreClosedPage extends Vue {
    public name = "StoreClosedPage";
    public eventId: number | undefined;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventid")) {
            this.eventId = Number(urlParams.get("eventid"));
        }
    }

    public data() {
        return {
            eventId: undefined,
        };
    }
}
