




























































import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import IEcomEventInfo from "@/chipply/events/IEcomEventInfo";
import { WebHelper } from "chipply-common";

@Component({
    props: { eventId: Number },
})
export default class StoreClosed extends Vue {
    public name = "StoreClosed";
    public eventId: number | undefined;
    public info: IEcomEventInfo | undefined;

    public async created() {
        if (this.eventId) {
            await this.getInfo();
        }
    }

    public data() {
        return {
            info: {},
        };
    }

    protected async getInfo() {
        this.info = await WebHelper.getJsonData(`/api/Event/GetInfo/${this.eventId}`);
    }
}
