import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vuetify";
import "es6-promise/dist/es6-promise.auto.min";
import "whatwg-fetch";
import StoreClosed from "@/components/page/StoreClosedPage.vue";
import RecaptchaClient from "@/chipply/verify/RecaptchaClient";

(window as any).StoreClosed = StoreClosed;
(window as any).RecaptchaClient = RecaptchaClient;

document.addEventListener("DOMContentLoaded", () => {
    const t = new StoreClosed({ vuetify, el: "#app" });
});

export default StoreClosed;
